@import '/src/styles/global';

.rowDanger {
    display: inline-flex;
    background-color: $--color-grid-danger;
    color: $--color-grid-font-danger;

    &__gridRowSelected {
        display: inline-flex;
        background-color: $--color-grid-select;
        color: $--color-grid-font-danger;
    }
}