@import '../../../styles/palette';

.common {
    box-sizing: border-box;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    flex-shrink: 0;
}

.stop {
    width: 21px;
    height: 21px;

    stroke: $warning;
    fill: $warning;
}

.allow {
    width: 21px;
    height: 21px;
    stroke: $success;
    fill: $success;
}

.block {
    width: 21px;
    height: 21px;
    stroke: $danger;
    fill: $danger;
}
.wrapper {
    display: flex;
    justify-content: flex-start;
}
.error {
    width: 21px;
    height: 21px;
    stroke: $gray-700;
}