.container {
    height: 100%;
    .wrapper {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .goods_width {
            width: 100%;
        }

        .grid {
            margin: 10px 0;
        }

        .accordionWrapper {
            margin: 20px;
        }
    }
}

.defaultMargin {
    >div {
        margin: 8px 0px 8px 0px !important;
      }
}