
.container {
    height: 100%;
    
    .wrapper {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .top10px {
            margin-top: 10px;
        }
    }
}

.lotPanel {
    align-items: center;
}
.defaultMargin {
    >div {
        margin: 8px 0px 8px 0px !important;
      }
}