@import "../../../styles/global";

.dropZone {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    padding: 10px 10px 10px 30px;
    background-color: #0066991a;
    margin-bottom: 10px;
    overflow-y: auto;
}

.disabledDropZone {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    padding: 10px 10px 10px 30px;
    margin-bottom: 10px;
    overflow-y: auto;
    background-color: #e9ecef;
}

.glWrapper {
    display: flex;
    justify-content: center;
    height: 90%;
}

.dropFile {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.files {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    height: 100%;
}

.oneFile {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.titleDrop {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.svg {
    width: 55px;
    height: 55px;
    stroke: #006699;
}

.title {
    font-size: $--font-size-14;
    font-weight: $--font-weight-regular;
    color: #006699;
}

.svgWrapper {
    display: flex;
    justify-content: center;

}

.wrapper {
    margin-right: 10px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px;
    height: 65%;
    max-height: 150px;
    width: 95%;
    max-width: 300px;
    border: 4px solid white;
    border-radius: 5px;
}

.loadingWrapper {
    @extend .wrapper;
    animation: move 8s infinite;
}

.failedWrapper {
    @extend .wrapper;
    border: 6px solid rgba(255, 0, 0, 0.384);
}

.failedAutoImportWrapper {
    @extend .wrapper;
    border: 4px solid rgba(0, 0, 255, 0.2);
}

.fileWrapper {
    display: flex;
    justify-content: space-between;
    height: 50px;
}

.fileName {
    margin-top: 20px;
    padding-top: 20px;
}

.checkBox {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    accent-color: rgb(0 117 255);
}

.recommendationName {
    color: rgba(215, 76, 76, 0.575);
    font-weight: 600;
}

.autoImportName {
    color: rgba(76, 76, 215, 0.575);
    font-weight: 600;
}

.fileIcon {
    width: 160px;
    height: 160px;
}

.deleteIcon {
    width: 50px;
    height: 50px;
    fill: rgba(255, 0, 0, 0.384);
}

.cross path {
    stroke: rgba(255, 0, 0, 0.438);
    stroke-width: 3px;
}

.deleteButton {
    cursor: pointer;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    width: 10%;
}

@keyframes move {
    0% {
        border: 5px solid transparent;
        border-image: linear-gradient(to left top, #4baee38a 10%, transparent 10%, transparent 10%, #4baee38a 100%);
        border-image-slice: 1;
    }

    10% {
        border: 5px solid transparent;
        border-image: linear-gradient(to left top, #4baee38a 30%, transparent 30%, transparent 30%, #4baee38a 100%);
        border-image-slice: 1;
    }

    20% {
        border: 5px solid transparent;
        border-image: linear-gradient(to left top, #4baee38a 50%, transparent 50%, transparent 50%, #4baee38a 100%);
        border-image-slice: 1;
    }

    30% {
        border: 5px solid transparent;
        border-image: linear-gradient(to left top, #4baee38a 70%, transparent 70%, transparent 70%, #4baee38a 100%);
        border-image-slice: 1;
    }

    40% {
        border: 5px solid transparent;
        border-image: linear-gradient(to left top, #4baee38a 90%, transparent 90%, transparent 90%, #4baee38a 100%);
        border-image-slice: 1;
    }

    50% {
        border: 5px solid transparent;
        border-image: linear-gradient(to left top, #4baee38a 100%, transparent 100%, transparent 100%, #4baee38a 100%);
        border-image-slice: 1;
    }

    60% {
        border: 5px solid transparent;
        border-image: linear-gradient(to left top, #4baee38a 80%, transparent 80%, transparent 80%, #4baee38a 100%);
        border-image-slice: 1;
    }

    70% {
        border: 5px solid transparent;
        border-image: linear-gradient(to left top, #4baee38a 60%, transparent 60%, transparent 60%, #4baee38a 100%);
        border-image-slice: 1;
    }

    80% {
        border: 5px solid transparent;
        border-image: linear-gradient(to left top, #4baee38a 40%, transparent 40%, transparent 40%, #4baee38a 100%);
        border-image-slice: 1;
    }

    90% {
        border: 5px solid transparent;
        border-image: linear-gradient(to left top, #4baee38a 20%, transparent 20%, transparent 20%, #4baee38a 100%);
        border-image-slice: 1;
    }

    100% {
        border: 5px solid transparent;
        border-image: linear-gradient(to left top, #4baee38a 0%, transparent 0%, transparent 0%, #4baee38a 100%);
        border-image-slice: 1;
    }

}