.wrapper {
    display: grid;
    gap: 0px 20px;
    grid-template-columns: repeat(12, 1fr);
}

.SettingsButton {
    display: flex;
}

.baseline { 
    align-items: baseline !important;
}