.container {
    height: 100%;

    .wrapper {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
}

.addIventPanel {
    grid-template-columns: 40% auto;
}

.defaultMargin {
    > div {
        margin: 8px 0px 8px 0px !important;
    }
}