.lotsFilteringPanel {
  display: flex;
  align-content: center;
  align-items: center;
  padding-top: 13px;

  .inlineInput {
    max-height: 34px;
  }

  .search {
    flex-basis: 47%;
    margin-right: 20px;
    max-height: 34px;
  }
  .filterBy {
    flex-basis: 20%;
    margin-right: 20px;
    max-height: 34px;
  }
  .autoFilter {
    flex-basis: 12%;
    margin-right: 20px;
    max-height: 34px;
    margin-bottom: 9px;
  }
  .withinRange {
    flex-basis: 12%;
    margin-right: 20px;
    width: 100%;
    max-height: 34px;
    margin-bottom: 9px;
  }
  .filterButton {
    flex-basis: 9%;
    margin-left: auto;
    max-height: 34px;
    margin-bottom: 10px;
  }
}
.width100 {
  width: 100%;
  padding-bottom: 0;
}


.lotsFilteringTwo {
  display: flex;
  align-items: center;


  .additionalFilter {
    display: flex;
    flex-basis: 20%;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 0;
    margin-top: 0;
    margin-right: 20px;
  }
  .storeFilter {
    display: flex;
    flex-basis: 20%;
    align-items: center;
    margin-bottom: 0;
    margin-top: 0;
    margin-right: 20px;
  }
  .pharmacyFilter {
    display: flex;
    flex-basis: 20%;
    align-items: center;
    margin-bottom: 0;
    margin-top: 0;
    margin-right: 20px;
  }
  .providerFilter {
    display: flex;
    flex-basis: 20%;
    align-items: center;
    margin-bottom: 0;
    margin-top: 0;
    margin-right: 20px;
  }
  .remainsOnlyFilter {
    display: flex;
    flex-basis: 12%;
    align-items: center;
    margin-left: auto;
    margin-bottom: 0;
    margin-top: 0;
  }
}
.lotsFilteringThree {
  display: flex;
  align-items: center;
  padding-bottom: 5px;

  .periodByFilter {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
    margin-bottom: 0;
    margin-top: 0;
  }
  .datePeriodFilter {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
    margin-bottom: 0;
    margin-top: 0;
  }
  .balanceOffBalanceFilter {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
    margin-bottom: 0;
    margin-top: 0;
  }
  .IZFilter {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
    margin-bottom: 0;
    margin-top: 0;
  }
  .OAFilter {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center; 
    margin-bottom: 0;
    margin-top: 0; 
  }

  
}

.field{
  &_search{
    grid-column: 1 / 8;
  }
  &_filterBy{
    grid-column: 8 / 10;
  }
  &_autoFilter{
    grid-column: 10 / 12;
    line-height: 50px; 
  }
  &_stopList{
    grid-column: 12 / 14;
    line-height: 50px; 
  }
  &_withinRange{
    grid-column: 14 / 16;
    line-height: 50px; 
  }
  &_button{
    grid-column: 16 / 18;
    // line-height: 50px; 
    
  }
}

.newField {
  &_alignCenter {
    align-items: center;
  }

  &_search {
    grid-column: 1/3;
  }

}

.checkbox {  
  float: left;
  padding: auto;
}