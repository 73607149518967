@import "../../../../../styles/global";

.modalWindow {
  width: 1040px;
  min-height: 191px;
  height: auto !important;

  footer > div {
    box-shadow: none !important;
  }
}

.modalWindowConfirm {
  max-width: 540px;
  min-width: 500px !important;
  min-height: 191px;
  height: auto !important;

  footer > div {
    box-shadow: none !important;
  }
}

.ToolsPanel {
  display: flex;
  justify-content: space-between;
}

.TabsPanel {
  position: relative;
  overflow: hidden;
  margin-bottom: 10px;
}

.Button {
  margin-top: 10px;
  height: 25px;
  grid-column: 5/6;
}

.InputPanel {
  margin-left: -5px;
  display: flex;
  margin-bottom: 10px;
}

.TextInput {
  grid-column: 1/5;
}

.NumberInput {
  margin: 5px;
}

.ScanCount {
  font-size: 16px;
  color: #339999;
}

.scanFormPanel {
  margin-bottom: 20px;
}

.inlineLabel {
  margin: 0px 0px 0px 0px !important;
}

.inlineInput {
  height: 38px !important;
}

.isNotTrusted {
  display: inline-flex;
  background-color: $--color-grid-danger;
  color: $--color-grid-font-danger;
}

.isTrusted {
  display: inline-flex;
  background-color:  rgba(40, 230, 40, 0.108);;
  color: black;
}

.actionSpinner {
  background-color: transparent;
}