@import '../../../../styles/global';

.lInputNormal {
  .labelInputBlue {
    color: #006699;
  }

  .labelInputGreen {
    color: $--color-green-300;
  }

  .labelInputDefault {
    color: #666;
  }

  .labelInputRequired {
    color: #666;
    font-weight: bold;
  }

  .errorMessage {
    color: $--color-grid-font-danger;
    font-size: 11px;
  }

  .inputError {
    border-color: $--color-grid-font-danger;
  }
  .inputWarning {
    border-color: $--color-grid-mark;
  }

  border: none;
  padding: 0;

  & > .labelControl {
    margin: 8px 0px 8px 0px;
    position: relative;
    width: 100%;
  }

  & > .noLabelControl {
    position: relative;
  }

  & > label {
    // Текст подписи к текстовому полю — отсутствует в _colors.scss
    font-weight: normal;
    font-size: $--font-size-14;
    line-height: $--line-height-20;
    margin-right: 10px;
    color: #666;
    white-space: nowrap;

    @include before('xs') {
      font-size: $--font-size-12;
      line-height: $--line-height-18;
    }
  }

  & > div {
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type='number'] {
      -moz-appearance: textfield;
    }
  }

  & > div > input,
  textarea {
    border: 1px solid $--color-gray-400;
    border-radius: $--border-radius;
    width: 100%;
    height: 36px;
    padding: 5px 0px 5px 6px;
    margin: 0px;
    font-weight: normal;
    font-size: $--font-size-14;
    line-height: $--line-height-20;
    color: $--color-text-body-100;
    //        margin-left: 2px;
    outline: none;
    @include corporateFocusedOutline;

    @include before('xs') {
      font-size: $--font-size-12;
      line-height: $--line-height-18;
    }
  }

  & > div > textarea {
    height: 70px;
    resize: none;
  }

  &Inline {
    display: flex;
    align-items: baseline;
  }
}

.largeTextArea {
  & > div > textarea {
    height: 210px;
    resize: none;
  }
}

.popupLabel {
  font-size: 12px;
  display: block;
  position: absolute;
  top: 40px;
  left: 10px;
  width: auto;
  max-width: 250px;
  height: auto;
  z-index: 2;
  border-radius: 4px;
  border: 1px solid $--color-grid-mark;
  padding: 5px;
  background-color: rgba(255, 255, 255, 0.956);
}
.lInputNormalInline {
  @extend .lInputNormal;

  & > .labelControl {
    margin: 8px 0px 8px 15px;
    position: relative;
    width: 100%;
  }
}

.lInputRed {
  @extend .lInputNormal;

  & > div > input {
    border: 1px solid $--color-status-negative;
  }
}

.lInputGreen {
  @extend .lInputNormal;

  & > div > input {
    border: 1px solid $--color-green-300;
  }

  & > div > input,
  textarea {
    color: $--color-green-300;
  }
}

.lInputBlue {
  @extend .lInputNormal;

  & > div > input {
    border: 1px solid #006699;
  }

  & > div > input,
  textarea {
    color: #006699;
  }

  & > label {
    color: #006699;
  }
}

.lInputDisabled {
  @extend .lInputNormal;

  input {
    background-color: $--color-gray-200;
  }
}

.lInputDisabledInline {
  @extend .lInputNormal;

  display: flex;
  align-items: center;

  input {
    background-color: $--color-gray-200;
  }

  div > input {
    background-color: $--color-gray-200;
  }

  & > .labelControl {
    margin: 8px 0px 8px 15px;
    position: relative;
    width: 100%;
  }
}

.timePicker {
  > div {
    display: flex;
  }
  
  input {
    border: 1px solid rgb(206, 212, 218);
    border-radius: 4px 0 0 4px;
    width: 100%;
    height: 36px;
    padding: 5px 0px 5px 6px;
    margin: 0px;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #333;
    outline: none;
  }

  a {
    display: none;
  }

  > div > div {
    background-color: $--color-gray-200;
    border: 1px solid $--color-gray-400;
    border-radius: 0 4px 4px 0;
    border-left: transparent;
  }
}

.decimalStringInput {
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  height: 36px;
  overflow: hidden;
  margin: 8px 0;

  input {
    border: none !important;
    outline: none !important;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    height: 100%;

    &.firstPart {
      flex: 1 1 100px;
      padding: 0 0 0 10px;
      border-radius: 4px 0 0 4px;
      width: 90px;
      text-align: right;
      @include before('xs') {
        width: 80px !important;
      }
    }

    &.secondPart {
      flex: 1 1 120px;
      border-radius: 0 4px 4px 0;
      width: 100px;
      padding: 0;
      @include before('xs') {
        width: 90px !important;
      }
    }
  }

  :focus {
    box-shadow: none !important;
  }

  .separator {
    margin: 0;
    font-size: 18px;
    line-height: 36px;
    border: none;
    outline: none;
    background: #fff;
  }
}