.field {
    &_Search {
        grid-column: 1 / 3;
    }

    &_SelectBlock {
        margin-top: 8px;
        grid-column: 3/5;
    }
}

.disabledbutton {
    pointer-events: none;
    opacity: 0.4;
}

.flexColumn {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #f9f9f9;
    padding: 0.5%;
}
.numberInput {
    width: 15%;
}
.button {
    margin: 1%;
}
.flexRow {
    width: 15%;
    margin-left: auto; 
    margin-right: 1.2%;
}

.legendStyle {
    font-size: 1.1em;
}

.fieldSet {
    border: 1px solid #00000075;
    border-radius: 5px;
    margin-bottom: 5px;
    padding: 10px;
}